import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';

function MovieManagement() {
  // 用于存储电影列表数据
  const [trendingMovies, setTrendingMovies] = useState([]);
  const [exclusiveMovies, setExclusiveMovies] = useState([]);
  // 用于存储新添加的电影数据
  const [newMovie, setNewMovie] = useState({
    title: '',
    category: 'trending',
    description: '',
    thumbnailUrl: '',
    detailImageUrl: ''
  });

  // 获取电影数据
  useEffect(() => {
    // 获取现在流行的电影
    axios.get(`${API_BASE_URL}/movies/trending`)
      .then(response => setTrendingMovies(response.data))
      .catch(error => console.error('Error fetching trending movies:', error));

    // 获取 Netflix 独播的电影
    axios.get(`${API_BASE_URL}/movies/netflix-exclusive`)
      .then(response => setExclusiveMovies(response.data))
      .catch(error => console.error('Error fetching exclusive movies:', error));
  }, []);

  // 添加电影
  const addMovie = () => {
    if (!newMovie.title || !newMovie.category || !newMovie.thumbnailUrl || !newMovie.detailImageUrl) {
      alert('请填写完整的电影信息');
      return;
    }

    console.log('准备发送的电影数据:', newMovie); // 添加日志查看发送的数据

    axios.post(`${API_BASE_URL}/movies`, newMovie)
      .then(() => {
        alert('电影添加成功');
        // 根据分类重新获取电影数据
        if (newMovie.category === 'trending') {
          axios.get(`${API_BASE_URL}/movies/trending`)
            .then(response => setTrendingMovies(response.data))
            .catch(error => console.error('Error fetching trending movies:', error));
        } else if (newMovie.category === 'netflix-exclusive') {
          axios.get(`${API_BASE_URL}/movies/netflix-exclusive`)
            .then(response => setExclusiveMovies(response.data))
            .catch(error => console.error('Error fetching exclusive movies:', error));
        }
        // 清空输入框
        setNewMovie({
          title: '',
          category: 'trending',
          description: '',
          thumbnailUrl: '',
          detailImageUrl: ''
        });
      })
      .catch(error => console.error('Error adding movie:', error));
  };

  // 删除电影
  const deleteMovie = (movieId, category) => {
    axios.delete(`${API_BASE_URL}/movies/${movieId}`)
      .then(() => {
        alert('电影删除成功');
        // 根据分类更新电影列表
        if (category === 'trending') {
          setTrendingMovies(trendingMovies.filter(movie => movie._id !== movieId));
        } else if (category === 'netflix-exclusive') {
          setExclusiveMovies(exclusiveMovies.filter(movie => movie._id !== movieId));
        }
      })
      .catch(error => console.error('Error deleting movie:', error));
  };

  // 渲染
  return (
    <div>
      <h2>电影管理</h2>
      <div className="add-movie-section">
        <h3>新增电影</h3>
        <input
          type="text"
          placeholder="电影标题"
          value={newMovie.title}
          onChange={e => setNewMovie({ ...newMovie, title: e.target.value })}
        />
        <select
          value={newMovie.category}
          onChange={e => setNewMovie({ ...newMovie, category: e.target.value })}
        >
          <option value="trending">现在流行</option>
          <option value="netflix-exclusive">仅限 Netflix 上播出</option>
        </select>
        <textarea
          placeholder="描述"
          value={newMovie.description}
          onChange={e => setNewMovie({ ...newMovie, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="缩略图 URL"
          value={newMovie.thumbnailUrl}
          onChange={e => setNewMovie({ ...newMovie, thumbnailUrl: e.target.value })}
        />
        <input
          type="text"
          placeholder="详情图 URL"
          value={newMovie.detailImageUrl}
          onChange={e => setNewMovie({ ...newMovie, detailImageUrl: e.target.value })}
        />
        <button onClick={addMovie}>添加电影</button>
      </div>
      <h3>现在流行的电影</h3>
      <ul className="movies-list">
        {trendingMovies.map(movie => (
          <li key={movie._id} className="movie-item">
            <span>{movie.title} - {movie.category}</span>
            {movie.thumbnailUrl && <img src={movie.thumbnailUrl} alt={movie.title} className="movie-thumbnail" />}
            <button className="delete-button" onClick={() => deleteMovie(movie._id, movie.category)}>删除</button>
          </li>
        ))}
      </ul>
      <h3>仅限 Netflix 上播出的电影</h3>
      <ul className="movies-list">
        {exclusiveMovies.map(movie => (
          <li key={movie._id} className="movie-item">
            <span>{movie.title} - {movie.category}</span>
            {movie.thumbnailUrl && <img src={movie.thumbnailUrl} alt={movie.title} className="movie-thumbnail" />}
            <button className="delete-button" onClick={() => deleteMovie(movie._id, movie.category)}>删除</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MovieManagement;
