import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import './FAQManagement.css';

const FAQManagement = () => {
  const [faqs, setFaqs] = useState([]);
  const [newFAQ, setNewFAQ] = useState({
    question: '',
    answer: ''
  });

  useEffect(() => {
    fetchFAQs();
  }, []);

  const fetchFAQs = () => {
    axios.get(`${API_BASE_URL}/faqs`)
      .then(response => setFaqs(response.data))
      .catch(error => console.error('Error fetching FAQs:', error));
  };

  const addFAQ = () => {
    axios.post(`${API_BASE_URL}/faqs`, newFAQ)
      .then(response => {
        alert('FAQ added successfully');
        setFaqs([...faqs, response.data]); // 使用从后端返回的数据更新状态，确保有唯一的 _id
        setNewFAQ({ question: '', answer: '' });
      })
      .catch(error => console.error('Error adding FAQ:', error));
  };

  const deleteFAQ = (id) => {
    axios.delete(`${API_BASE_URL}/faqs/${id}`)
      .then(() => {
        alert('FAQ deleted successfully');
        setFaqs(faqs.filter(faq => faq._id !== id));
      })
      .catch(error => console.error('Error deleting FAQ:', error));
  };

  return (
    <div className="faq-container">
      <h2 className="faq-header">常见问题管理</h2>
      <div className="new-faq">
        <h3>新增问题</h3>
        <input 
          type="text" 
          placeholder="问题" 
          value={newFAQ.question} 
          onChange={e => setNewFAQ({ ...newFAQ, question: e.target.value })} 
        />
        <textarea 
          placeholder="答案" 
          value={newFAQ.answer} 
          onChange={e => setNewFAQ({ ...newFAQ, answer: e.target.value })} 
        />
        <button onClick={addFAQ}>添加问题</button>
      </div>
      <h3>问题列表</h3>
      <ul className="faq-list">
        {faqs.map(faq => (
          <li key={faq._id} className="faq-item">
            <div className="faq-item-question">{faq.question}</div>
            <div className="faq-item-answer">{faq.answer}</div>
            <button className="delete-faq-button" onClick={() => deleteFAQ(faq._id)}>删除</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQManagement;