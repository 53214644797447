import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePageManagement from './pages/HomePageManagement';
import MovieManagement from './pages/MovieManagement';
import FAQManagement from './pages/FAQManagement';
import FooterManagement from './pages/FooterManagement';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* 为根路径添加一个默认的首页路由 */}
          <Route path="/" element={<HomePageManagement />} />
          <Route path="/home" element={<HomePageManagement />} />
          <Route path="/movies" element={<MovieManagement />} />
          <Route path="/faq" element={<FAQManagement />} />
          <Route path="/footer" element={<FooterManagement />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
