import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BannerManagement({ apiUrl }) {
  const [bannerImage, setBannerImage] = useState('');
  const [bannerId, setBannerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // 获取当前 Banner 数据
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiUrl}/banners`)
      .then(response => {
        if (response.data.length > 0) {
          const banner = response.data[0];
          setBannerImage(banner.imageUrl);
          setBannerId(banner.id || banner._id); // 适配后端的 id 或 _id 字段
        } else {
          console.warn('No banners found');
        }
      })
      .catch(err => {
        console.error('Error fetching banner data:', err);
        setError('获取 Banner 数据失败');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUrl]);

  // 更新 Banner 数据
  const updateBannerImage = () => {
    if (!bannerId) {
      alert('No banner to update');
      return;
    }

    setLoading(true);
    setError('');
    axios
      .put(
        `${apiUrl}/banners/${bannerId}`,
        { imageUrl: bannerImage },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(response => {
        setBannerImage(response.data.imageUrl);
        alert('Banner image updated successfully');
      })
      .catch(err => {
        console.error('Error updating banner image:', err);
        setError('更新 Banner 数据失败');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="banner-management">
      <h3 className="sub-section-title">首页大图管理</h3>
      {loading && <p>加载中...</p>}
      {error && <p className="error">{error}</p>}
      <input
        type="text"
        className="input-field"
        placeholder="Banner Image URL"
        value={bannerImage}
        onChange={e => setBannerImage(e.target.value)}
      />
      <button
        className="btn update-btn"
        onClick={updateBannerImage}
        disabled={loading || !bannerImage}
      >
        {loading ? '更新中...' : '更新大图'}
      </button>
      {bannerImage && (
        <img
          src={bannerImage}
          alt="Banner Preview"
          className="banner-preview"
        />
      )}
    </div>
  );
}

export default BannerManagement;
