import React from 'react';
import BannerManagement from './BannerManagement';
import MovieManagement from './MovieManagement';
import FAQManagement from './FAQManagement';
import FooterManagement from './FooterManagement';
import API_BASE_URL from '../config';
import './HomePageManagement.css';
import './BannerManagement.css';
import './MovieManagement.css';
import './FAQManagement.css';
import './FooterManagement.css';

function HomePageManagement() {
  return (
    <div className="homepage-management">
      <h2 className="section-title">首页管理</h2>
      <BannerManagement apiUrl={API_BASE_URL} />
      <MovieManagement apiUrl={API_BASE_URL} />
      <FAQManagement apiUrl={API_BASE_URL} />
      <FooterManagement apiUrl={API_BASE_URL} />
    </div>
  );
}

export default HomePageManagement;
