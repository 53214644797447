import React, { useState, useEffect } from "react";
import { Button, Input, List, message, Select } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faGithub, faPinterest, faReddit, faSnapchat, faTiktok, faTumblr, faWhatsapp, faDiscord, faDribbble, faFlickr, faSkype, faVimeo, faVk, faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'; // 使用 faCircleQuestion 替代 faQuestionCircle

import './FooterManagement.css'; // 引入 CSS 样式

const { Option } = Select;

function getIconByName(name) {
  if (!name) return faCircleQuestion; // 默认返回问号图标
  switch (name.toLowerCase()) {
    case 'facebook':
      return faFacebook;
    case 'twitter':
      return faTwitter;
    case 'instagram':
      return faInstagram;
    case 'linkedin':
      return faLinkedin;
    case 'youtube':
      return faYoutube;
    case 'github':
      return faGithub;
    case 'pinterest':
      return faPinterest;
    case 'reddit':
      return faReddit;
    case 'snapchat':
      return faSnapchat;
    case 'tiktok':
      return faTiktok;
    case 'tumblr':
      return faTumblr;
    case 'whatsapp':
      return faWhatsapp;
    case 'discord':
      return faDiscord;
    case 'dribbble':
      return faDribbble;
    case 'flickr':
      return faFlickr;
    case 'skype':
      return faSkype;
    case 'vimeo':
      return faVimeo;
    case 'vk':
      return faVk;
    case 'weibo':
      return faWeibo;
    default:
      return faCircleQuestion; // 未知社交平台时返回问号图标
  }
}

function FooterManagement({ apiUrl }) {
  const [footerData, setFooterData] = useState({ description: "", socialLinks: [] });
  const [newSocialLink, setNewSocialLink] = useState({ name: "", url: "" });

  useEffect(() => {
    fetch(`${apiUrl}/footer`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setFooterData(data);
        }
      })
      .catch((error) => {
        console.error("获取 Footer 数据失败: ", error);
      });
  }, [apiUrl]);

  const handleAddSocialLink = () => {
    if (!newSocialLink.name || !newSocialLink.url) {
      message.warning("请确保所有社交链接字段都已填写");
      return;
    }

    fetch(`${apiUrl}/footer/social-links`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newSocialLink),
    })
      .then((res) => res.json())
      .then((data) => {
        setFooterData(data);
        setNewSocialLink({ name: "", url: "" });
        message.success("添加社交链接成功");
      })
      .catch((error) => {
        console.error("添加社交链接失败: ", error);
        message.error("添加社交链接失败");
      });
  };

  const handleDeleteSocialLink = (id) => {
    if (!id) {
      message.error("删除失败，找不到有效的社交链接 ID");
      return;
    }

    fetch(`${apiUrl}/footer/social-links/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("删除请求失败");
        }
        setFooterData((prevData) => ({
          ...prevData,
          socialLinks: prevData.socialLinks.filter((link) => link._id !== id),
        }));
        message.success("删除社交链接成功");
      })
      .catch((error) => {
        console.error("删除社交链接失败: ", error);
        message.error("删除社交链接失败");
      });
  };

  const handleSaveFooterData = () => {
    if (!footerData.description.trim()) {
      message.warning("描述不能为空");
      return;
    }

    fetch(`${apiUrl}/footer`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(footerData),
    })
      .then((res) => res.json())
      .then((data) => {
        message.success("页脚数据已保存");
        setFooterData(data);
      })
      .catch((error) => {
        console.error("保存页脚数据失败: ", error);
        message.error("保存页脚数据失败");
      });
  };

  return (
    <div className="footer-management">
      <h3 className="sub-section-title">页脚管理</h3>
      <Input
        type="text"
        className="input-field"
        placeholder="Description"
        value={footerData.description}
        onChange={(e) => setFooterData({ ...footerData, description: e.target.value })}
      />
      <h4 className="sub-section-title">社交链接</h4>
      <List
        dataSource={footerData.socialLinks}
        renderItem={(link) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => handleDeleteSocialLink(link._id)}>
                删除
              </Button>,
            ]}
          >
            <FontAwesomeIcon icon={getIconByName(link.name)} style={{ marginRight: '8px' }} />
            <span className="social-link-platform">{link.name}</span>
            <span className="social-link-url"> - {link.url}</span>
          </List.Item>
        )}
      />
      <Select
        showSearch
        placeholder="选择平台名称"
        value={newSocialLink.name}
        onChange={(value) => setNewSocialLink({ ...newSocialLink, name: value })}
        className="input-field select-field"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="facebook">Facebook</Option>
        <Option value="twitter">Twitter</Option>
        <Option value="instagram">Instagram</Option>
        <Option value="linkedin">LinkedIn</Option>
        <Option value="youtube">YouTube</Option>
        <Option value="github">GitHub</Option>
        <Option value="pinterest">Pinterest</Option>
        <Option value="reddit">Reddit</Option>
        <Option value="snapchat">Snapchat</Option>
        <Option value="tiktok">TikTok</Option>
        <Option value="tumblr">Tumblr</Option>
        <Option value="whatsapp">WhatsApp</Option>
        <Option value="discord">Discord</Option>
        <Option value="dribbble">Dribbble</Option>
        <Option value="flickr">Flickr</Option>
        <Option value="skype">Skype</Option>
        <Option value="vimeo">Vimeo</Option>
        <Option value="vk">VK</Option>
        <Option value="weibo">Weibo</Option>
      </Select>
      <Input
        type="text"
        className="input-field"
        placeholder="Social Link URL"
        value={newSocialLink.url}
        onChange={(e) => setNewSocialLink({ ...newSocialLink, url: e.target.value })}
      />
      <Button className="btn add-btn" onClick={handleAddSocialLink}>
        添加社交链接
      </Button>
      <Button type="primary" onClick={handleSaveFooterData}>
        保存页脚数据
      </Button>
    </div>
  );
}

export default FooterManagement;